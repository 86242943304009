.form-item-selector {
  display: flex;

  .btn {
    flex-grow: 1;
    flex-basis: 100%;
    text-align: center;
    outline: none !important;
    box-shadow: none !important;
    background: $form-item-selector-button-background-color;

    @include media-breakpoint-down(xs) {
      font-size: $form-item-selector-btn__xs-font-size;
      padding: $form-item-selector-btn__xs-padding-y $form-item-selector-btn__xs-padding-x;
    }

    & + .btn {
      border-left: none;
    }

    @include hover-focus-active() {
      box-shadow: none !important;
      outline: none !important;
      background: $form-item-selector-btn-active-background-color;
      color: $form-item-selector-btn-active-color;
      border-color: $form-item-selector-btn-active-background-color;
    }

    &.active {
      border-color: $form-item-selector-btn-active-background-color;
      background: $form-item-selector-btn-active-background-color;
      color: $form-item-selector-btn-active-color;
    }
  }
}