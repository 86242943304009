// .address-selector
$address-selector-font-size: 14px;

// .address-selector .label
$address-selector-label-text-transform: uppercase;
$address-selector-label-text-align: center;
$address-selector-label-font-weight: bold;
$address-selector-label__md-font-size: 90%;
$address-selector-label-padding-bottom: $spacer;

// .address-selector .addresses .address
$address-selector-addresses-address-margin-top: 15px;
$address-selector-addresses-address-padding-top: 15px;
$address-selector-addresses-address-border-top: 1px solid #dcdcdc;
