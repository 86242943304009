.footer {
  border: none;

  .border {
    border: none !important;
    height: 1px;
    background: $app-footer-border-background;
  }

  .content {
    padding: $app-footer-content-padding-y $app-footer-content-padding-x;
    background: $app-footer-content-background-color;
    color: $app-footer-content-color;
  }

  .copyright {
    font-family: $app-footer-copyright-font-family;
    font-size: $app-footer-copyright-font-size;
    text-align: $app-footer-copyright-text-align;
  }
}