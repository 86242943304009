.address-form {

  .title {
    text-align: $address-form-title-text-align;
    text-transform: $address-form-title-text-transform;
    font-weight: $address-form-title-text-font-weight;
    @each $property, $value in $address-form-title-text-paddings {
      padding-#{$property}: $value;
    }
  }

  .info {
    font-size: $address-form-info-font-size;
    text-align: $address-form-info-text-align;
    color: $address-form-info-color;
  }
}