.address-selector {
  font-size: $address-selector-font-size;

  .label {
    font-weight: $address-selector-label-font-weight;
    padding-bottom: $address-selector-label-padding-bottom;
    text-transform: $address-selector-label-text-transform;
    text-align: $address-selector-label-text-align;

    @include media-breakpoint-only(md) {
      font-size: $address-selector-label__md-font-size;
    }
  }

  .addresses .address {
    display: flex;

    & + .address {
      margin-top: $address-selector-addresses-address-margin-top;
      padding-top: $address-selector-addresses-address-padding-top;
      border-top: $address-selector-addresses-address-border-top;
    }

    .form-check {
      flex-grow: 1;
      width: 100%;
    }
  }
}