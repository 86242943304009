.carousel {
  position: relative;
  margin: auto;
  max-width: $carousel-max-width;
  padding-bottom: $carousel-padding-bottom;

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__slide {
    flex: 0 0 100%;
    height: 100%;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }

  &__arrows {
    cursor: pointer;
    position: absolute;
    top: 50%;
    bottom: 0;
    width: $carousel-arrows-width;
    height: $carousel-arrows-height;
    padding: $carousel-arrows-padding;
    margin-top: $carousel-arrows-height * -0.5;

    &__shift {
      margin-top: $carousel-arrows-shift;
    }

    &--left,
    &--right {
      border: none;
      outline: none;
      background: none;
      padding: $carousel-arrows-padding;
      color: $carousel-arrows-color;

      @include hover-focus-active() {
        outline: none;
      }
    }

    &--left {
      left: $carousel-arrows-width / -2;
    }

    &--right {
      right: $carousel-arrows-width / -2;
    }
  }

  &__dots {
    display: block;
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    text-align: center;

    &__dot {
      display: inline-block;
      width: $carousel-dots-dot-width;
      height: $carousel-dots-dot-height;
      border: $carousel-dots-dot-border-width $carousel-dots-dot-border-style $carousel-dots-dot-border-color;
      border-radius: $carousel-dots-dot-border-radius;

      & + & {
        margin-left: $carousel-dots-dot-margin-left;
      }

      &__active {
        background: $carousel-dots-dot-active-background-color;
      }
    }
  }
}