$carousel-max-width: 300px;
$carousel-padding-bottom: $spacer * 1.5;

$carousel-arrows-size: 48px;
$carousel-arrows-width: $carousel-arrows-size;
$carousel-arrows-height: $carousel-arrows-size;
$carousel-arrows-color: $black;
$carousel-arrows-padding: 0;
$carousel-arrows-shift: -34px;

$carousel-dots-dot-size: $spacer * 0.5;
$carousel-dots-dot-width: $carousel-dots-dot-size;
$carousel-dots-dot-height: $carousel-dots-dot-size;
$carousel-dots-dot-border-style: solid;
$carousel-dots-dot-border-width: 1px;
$carousel-dots-dot-border-color: $black;
$carousel-dots-dot-border-radius: 50%;
$carousel-dots-dot-margin-left: $spacer * 0.75;

$carousel-dots-dot-active-background-color: $black;