.approvals-delegate {
  .items {
    .email {
      width: 100%;
    }

    .date {
      min-width: 120px;
      width: 120px;
    }

    .control {
      width: 40px;
    }
  }
}