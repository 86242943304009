.products-list-product {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .title {
    font-size: $products-list-product-title-font-size;
    line-height: $products-list-product-title-line-height;
    color: $products-list-product-title-color;
    font-weight: $products-list-product-title-font-weight;

    @include media-breakpoint-only(md) {
      font-size: $products-list-product-title__md-font-size;
    }

    a {
      font-size: $products-list-product-title-font-size;
      line-height: $products-list-product-title-line-height;
      color: $products-list-product-title-color;
      font-weight: $products-list-product-title-font-weight;
    }

    .note {
      color: $products-list-product-title-note-color;
      font-weight: $products-list-product-title-note-font-weight;
      font-style: $products-list-product-title-note-font-style;
      font-size: $products-list-product-title-note-font-size;
      line-height: $products-list-product-title-note-line-height;

      @include media-breakpoint-only(md) {
        font-size: $products-list-product-title-note__md-font-size;
      }
    }
  }

  .image {
    max-width: $products-list-product-image-width;
    width: $products-list-product-image-width;
    border: $products-list-product-image-border;
  }

  .cart-toast {
    position: relative;

    .message {
      position: absolute;
      opacity: 0;
      left: 0;
      top: $products-list-product-cart-toast-message-padding-top;
      right: 0;
      text-align: $products-list-product-cart-toast-message-text-align;
      font-size: $products-list-product-cart-toast-message-font-size;
      transition: opacity 0.2s;

      &.display{
        opacity: 1;
      }
    }
  }
}