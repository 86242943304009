.orders-history-order-product {
  display: flex;
  font-size: $orders-history-order-product-font-size;

  @each $property, $value in $orders-history-order-product-margins {
    margin-#{$property}: $value;
  }

  & + & {
    margin-top: $orders-history-order-product__plus-margin-top;
  }

  .orders-history-order-product-thumbnail {
    width: $orders-history-order-product-thumbnail-width;

    @each $property, $value in $orders-history-order-product-thumbnail-margins {
      margin-#{$property}: $value;
    }

    .orders-history-order-product-thumbnail-img {
      display: block;
      border: $orders-history-order-product-thumbnail-border;
      width: $orders-history-order-product-thumbnail-width;
      max-width: $orders-history-order-product-thumbnail-width;
    }
  }

  .orders-history-order-product-name {
    flex-grow: 1;
    display: flex;
    font-weight: normal;

    .orders-history-order-date {
      font-size: 80%;
      padding-left: $spacer;
      word-break: keep-all;
      white-space: nowrap;
    }
  }

  .info {
    font-size: 80%;

    @each $property, $value in $orders-history-order-product-info-paddings {
      padding-#{$property}: $value;
    }
  }

  .control {
    text-align: $orders-history-order-product-status-control-text-align;
    padding-top: $spacer * 0.5;

    .popper {
      text-align: left;
    }

    button {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      height: auto !important;
      font-size: $orders-history-order-product-status-control-btn-control-font-size;
      text-align: $orders-history-order-product-status-control-btn-control-text-align;

      @include hover-focus-active() {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  .request-cancel {
    @each $property, $value in $orders-history-order-product-request-cancel-paddings {
      padding-#{$property}: $value;
    }
  }

  .tracking {
    @each $property, $value in $orders-history-order-product-tracking-paddings {
      padding-#{$property}: $value;
    }
    font-weight: $orders-history-order-product-tracking-font-weight;
  }

  .status {
    @each $property, $value in $orders-history-order-product-status-paddings {
      padding-#{$property}: $value;
    }
  }
}