$btn-border-radius: 8px;
$btn-text-transform: none;
$btn-font-weight: 400;

$btn-sm-height: 34px;
$btn-sm-padding-x: 16px;
$btn-sm-line-height: 34px;

.btn {
  font-size: 14px !important;
  line-height: 16px;

  &.btn-outline-secondary {
    background: #fff;
  }
}
