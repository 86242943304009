.page-message {
  @each $property, $value in $page-message-margins {
    margin-#{$property}: $value;
  }

  &:before {
    display: block;
    content: "";
    height: $page-message-before-height;
    background: $page-message-before-background;

    @include media-breakpoint-only(xs) {
      height: $page-message-before__xs__height;
    }
  }

  .content {
    margin-top: -100px;
    min-height: 200px;
    text-align: $page-message-content-text-align;
    background: $page-message-content-background;
    border-radius: $page-message-content-border-radius;
    box-shadow: $page-message-content-box-shadow;

    @each $property, $value in $page-message-content-paddings {
      padding-#{$property}: $value;
    }

    @include media-breakpoint-down(sm) {
      @each $property, $value in $page-message-content__sm-paddings {
        padding-#{$property}: $value;
      }
    }

    .header {
      font-size: $page-message-content-header-font-size;
      line-height: $page-message-content-header-line-height;
      color: $page-message-content-header-color;
      font-weight: $page-message-content-header-font-weight;
      @each $property, $value in $page-message-content-header-margins {
        margin-#{$property}: $value;
      }
    }

    .message {
      font-size: $page-message-content-message-font-size;
      line-height: $page-message-content-message-line-height;
      color: $page-message-content-message-color;
    }

    .cta {
      @each $property, $value in $page-message-content-cta-paddings {
        padding-#{$property}: $value;
      }
    }
  }
}