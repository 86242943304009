
.icon-user:before { content: '\e800'; } /* '' */
.icon-basket:before { content: '\e801'; } /* '' */
.icon-attention-circled:before { content: '\e802'; } /* '' */
.icon-down-dir:before { content: '\e803'; } /* '' */
.icon-up-dir:before { content: '\e804'; } /* '' */
.icon-left-dir:before { content: '\e805'; } /* '' */
.icon-right-dir:before { content: '\e806'; } /* '' */
.icon-down-open:before { content: '\e807'; } /* '' */
.icon-left-open:before { content: '\e808'; } /* '' */
.icon-right-open:before { content: '\e809'; } /* '' */
.icon-up-open:before { content: '\e80a'; } /* '' */
.icon-plus:before { content: '\e80b'; } /* '' */
.icon-minus:before { content: '\e80c'; } /* '' */
.icon-delete:before { content: '\e80d'; } /* '' */
.icon-cancel:before { content: '\e80e'; } /* '' */
.icon-info-circled:before { content: '\e80f'; } /* '' */
.icon-pencil:before { content: '\e810'; } /* '' */
.icon-spinner:before { content: '\f529'; } /* '' */
