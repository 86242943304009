.btn {
  min-height: $btn-height;
  text-transform: $btn-text-transform;
  letter-spacing: $btn-letter-spacing;
  border-radius: $btn-border-radius;
  font-size: $btn-font-size;

  @each $property, $value in $btn-paddings {
    padding-#{$property}: $value;
  }

  &.btn-primary {
    color: $btn-primary-color;
    background: $btn-primary-background;
    box-shadow: $btn-primary-box-shadow;
    transition: $btn-primary-transition;
    transform: $btn-primary-transform;
    background-position: $btn-primary-background-position;
    background-size: $btn-primary-background-size;

    @include hover-focus() {
      background: $btn-primary-hover-background;
      color: $btn-primary-hover-color;
      box-shadow: $btn-primary-hover-box-shadow;
      transform: $btn-primary-hover-transform !important;
      background-size: $btn-primary-hover-background-size;
      background-position: $btn-primary-hover-background-position;
    }
  }

  &.btn-sm {
    min-height: $btn-sm-height;
    @each $property, $value in $btn-sm-paddings {
      padding-#{$property}: $value;
    }
  }

  &.btn-outline-primary {
    background: $btn-outline-primary-background-color;
    color: $btn-outline-primary-color;
    border: $btn-outline-primary-border;
    box-shadow: $btn-outline-primary-box-shadow;
    transition: $btn-outline-primary-transition;
    transform: $btn-outline-primary-transform;

    @include hover-focus() {
      background: $btn-outline-primary-hover-background !important;
      color: $btn-outline-primary-hover-color;
      border: $btn-outline-primary-hover-border;
      box-shadow: $btn-outline-primary-hover-box-shadow;
      transform: $btn-outline-primary-hover-transform;
    }
  }
}

.btn-switcher {
  width: $btn-switcher-size;
  height: $btn-switcher-size;
  line-height: $btn-switcher-line-height;
  background: $btn-switcher-background;
  border-radius: $btn-switcher-border-radius;
  border: $btn-switcher-border;
  padding: 0;
  margin: 0;
  text-align: center;
}

.btn-group {
  // Reset rounded corners
  > .btn:not(:last-child):not(.dropdown-toggle),
  > .btn-group:not(:last-child) > .btn {
    @include border-right-radius(0);
  }

  > .btn:not(:first-child),
  > .btn-group:not(:first-child) > .btn {
    @include border-left-radius(0);
  }
}

a.btn.btn-sm {
  line-height: $btn-sm-line-height;
}