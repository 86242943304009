$modals-overlay-background-color: rgba(0, 0, 0, 0.5);

$modals-modal-background-color: $body-bg;

$modals-modal-close-size: 30px;
$modals-modal-close-color: $secondary;
$modals-modal-close-active-color: $primary;

$modals-modal-header-padding-y: $spacer;
$modals-modal-header-padding-x: $spacer;
$modals-modal-header-border-bottom-width: 1px;
$modals-modal-header-border-bottom-style: solid;
$modals-modal-header-border-bottom-color: #e5e5e5;
$modals-modal-header-color: $secondary;
$modals-modal-header-font-size: $h4-font-size;
$modals-modal-header__sm-font-size: $h5-font-size;

$modals-modal-border-radius: 12px;
$modals-modal-body-padding-y: $spacer;
$modals-modal-body-padding-x: $spacer;

$modals-modal-body-title-font-size: $h3-font-size;
$modals-modal-body-title-padding-bottom: $spacer * 0.5;

$modals-modal-footer-padding-y: $spacer;
$modals-modal-footer-padding-x: $spacer;
$modals-modal-footer-text-align: center;

$modals-modal-footer-button-margin-y: 0;
$modals-modal-footer-button-margin-x: $spacer;