.impersonation-orders-history {
  .title {
    font-weight: bold;
    padding-bottom: $spacer;
  }

  .error {
    color: $danger;
    font-size: $small-font-size;
  }

  .no-impersonation-orders {
    font-size: 80%;
  }

  .load-more {
    text-align: center;

    > button.btn.btn-link {
      outline: none !important;
      @include hover-focus-active() {
        outline: none !important;
      }
    }
  }
}