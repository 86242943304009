// .categories-list-category .title
$categories-list-category-title-order: 1;
$categories-list-category-title-margin: 0 0 $spacer/2 0;
$categories-list-category-title-background: #e9e0f7;
$categories-list-category-title-font-weight: bold;
$categories-list-category-title-color: #5f249f;
$categories-list-category-title-margin: 0 unset 8px 0;

// .categories-list-category .thumbnail
$categories-list-category-thumbnail-box-shadow: none;

// .categories-list-category .image
$categories-list-category-thumbnail-order: 2;
$categories-list-category-thumbnail-margin-bottom: 0;


.categories-list-category {
  border-radius: 12px;
  box-shadow: $panel-box-shadow;
  padding: $spacer;
  margin-bottom: 12px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    height: 20px;
    padding: 2px 8px;
    font-size: 10px !important;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: .2px;
    border-radius: 20px;
    position: initial;

    @include hover-focus-active() {

      text-decoration: none;
    }
  }

  .thumbnail {
    padding-bottom: 30px;
    position: relative;

    &:active,
    &:hover {
      &:after {
        box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
      }
    }

    &:after {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #7229c0;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      content: '\e809';
      font-family: fontello;
      color: #fff;
      text-align: center;
      line-height: 36px;
      font-weight: normal;
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    }
  }
}