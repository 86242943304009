// .page-message::before
$page-message-before-height: 300px;
$page-message-before__xs__height: 240px;
$page-message-before-background: $app-background-gradient;

// .page-message
$page-message-margins: (
        "top": $spacer * -1,
        "right": 0,
        "bottom": 0,
        "left": 0,
);

// .page-message .content
$page-message-content-text-align: center;
$page-message-content-background: #fff;
$page-message-content-border-radius: 12px;
$page-message-content-box-shadow: 4px 10px 30px 4px rgba(36, 14, 47, 0.06);
$page-message-content-paddings: (
        "top": 50px,
        "right": $spacer,
        "bottom": $spacer,
        "left": $spacer,
);
// .page-message .content down(sm)
$page-message-content__sm-paddings: (
        "top": $spacer * 2,
        "right": 0,
        "bottom": $spacer * 2,
        "left": 0,
);

// .page-message .content .header
$page-message-content-header-font-size: 24px !important;
$page-message-content-header-line-height: 28px;
$page-message-content-header-color: $app-color-primary;
$page-message-content-header-font-weight: 600;
$page-message-content-header-margins: (
        "top":0,
        "right":0,
        "bottom": $spacer * 2,
        "left": 0
);

// .page-message .content .message
$page-message-content-message-color: #1F2020;
$page-message-content-message-line-height: 22px;
$page-message-content-message-font-size: 14px;

// .page-message .content .cta
$page-message-content-cta-paddings: (
        "top":$spacer / 2,
        "right":$spacer / 2,
        "bottom": $spacer / 2,
        "left": $spacer / 2
);