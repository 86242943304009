.approvals-list-item-prior-cta {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 12px;
  line-height: 1.2;

  &:hover, &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}