.form-autocomplete {
  position: relative;

  .result-items {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 100%;
    right: 0;
    border: 1px solid $form-control-border-color;
    border-radius: $form-control-border-radius;
    font-size: $form-control-font-size;
    line-height: $form-control-line-height;
    background: $form-control-background;
    min-width: 200px;
    max-height: 200px;
    overflow: auto;

    .item {
      padding: $form-control-padding;

      &:hover,
      &:focus,
      &.selected {
        background: darken($form-control-background, 10%);
        cursor: pointer;
      }
    }
  }
}