.collection {

  &-error {
    text-align: center;
    padding: $collection-error-padding-y $collection-error-padding-x;
    font-size: $collection-error-font-size;
    color: $collection-error-color;
  }

  &-item {
    text-align: center;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    color: $collection-item-color;

    @include hover-focus-active() {
      outline: none;
    }

    &-selected img {
      border: $collection-item-selected-img-border-width $collection-item-selected-img-border-style $collection-item-selected-img-border-color;
    }

    &-image {
      display: block;
      max-width: 100%;
      border: $collection-item-image-border-width $collection-item-image-border-style $collection-item-image-border-color;
      margin: $collection-item-image-margin-top auto $collection-item-image-margin-bottom;
    }

    &-title {
      display: block;
      margin: $collection-item-title-margin-top auto $collection-item-title-margin-bottom;
      font-weight: $collection-item-title-font-weight;
    }

    &-description {
      display: block;
      font-size: $collection-item-description-font-size;
      margin: $collection-item-description-margin-top auto $collection-item-description-margin-bottom;
    }
  }
}