.address-viewer {
  width: 100%;
  display: flex;

  .address {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: $address-viewer-address-min-width;

    .item {
      display: block;
    }
  }

  .control {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    text-align: right;

    .btn-control {
      text-transform: $address-viewer-btn-control-text-transform;
      font-size: $address-viewer-btn-control-font-size;
      text-align: $address-viewer-btn-control-text-align;
      line-height: $address-viewer-btn-control-line-height;
      @each $property, $value in $address-viewer-btn-control-paddings {
        padding-#{$property}: $value;
      }
    }

    .title {
      display: block;
      font-size: $address-viewer-unverified-title-font-size;
      text-transform: $address-viewer-unverified-title-text-transform;
    }

    .btn-unverified {

    }
  }
}