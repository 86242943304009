.panel {
  background: $panel-background-color;
  box-shadow: $panel-box-shadow;
  border-radius: $panel-border-radius;

  @each $property, $value in $panel-paddings {
    padding-#{$property}: $value;
  }

  @each $property, $value in $panel-borders {
    border-#{$property}: $value;
  }

  &.panel-secondary {
    background: $panel-secondary-background-color;
  }
}