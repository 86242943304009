// .impersonation-orders-history-user-product
$impersonation-orders-history-user-product-font-size: 90%;
$impersonation-orders-history-user-product-margins: (
        "top": 0,
        "right": 0,
        "bottom":0,
        "left":0
);

// .impersonation-orders-history-user-product .impersonation-orders-history-user-product-thumbnail
$impersonation-orders-history-user-product-thumbnail-width: 50px;
$impersonation-orders-history-user-product-thumbnail-border: none;

$impersonation-orders-history-user-product-thumbnail-margins: (
        "top": 0,
        "right": $spacer,
        "bottom": 0,
        "left": 0
);

// .impersonation-orders-history-user-product + .impersonation-orders-history-user-product
$impersonation-orders-history-user-product__plus-margin-top: $spacer;

// .impersonation-orders-history-user-product .info
$impersonation-orders-history-user-product-info-paddings: (
        "top": 0,
        "right": 0,
        "bottom": 0,
        "left": 0
);

// .impersonation-orders-history-user-product .status .control
$impersonation-orders-history-user-product-status-control-text-align: right;

// .impersonation-orders-history-user-product .status .control .btn-control
$impersonation-orders-history-user-product-status-control-btn-control-font-size: 12px;
$impersonation-orders-history-user-product-status-control-btn-control-text-align: right;

// .impersonation-orders-history-user-product .request-cancel
$impersonation-orders-history-user-product-request-cancel-paddings: (
        "left": 0
);

// .impersonation-orders-history-user-product .tracking
$impersonation-orders-history-user-product-tracking-paddings: (
        "left": 0
);
$impersonation-orders-history-user-product-tracking-font-weight: normal;

// .impersonation-orders-history-user-product .status
$impersonation-orders-history-user-product-status-paddings: (
        "left": 0
);