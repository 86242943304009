// .auth-login .auth-banner
$views-auth-login-banner-color: $app-color-white;
$views-auth-login-banner-background-color: $app-background-gradient;
$views-auth-login-banner-paddings: (
        "top": $spacer * 3,
        "right": $spacer * 3,
        "bottom": $spacer * 3,
        "left": $spacer * 3
);

$views-auth-login-banner-margins: (
        "top": $spacer * -1,
        "right": 0,
        "bottom": 0,
        "left": 0
);