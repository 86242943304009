// headers
$heading-font-style: normal;
$heading-font-weight: normal;

// h1, .h1
$heading-h1-font-size: 28px;
$heading-h1-line-height: 40px;

// .label-required
$label-required-color: $danger;
$label-required-paddings: (
        "left":$spacer * 0.25
);
