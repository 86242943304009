.layout-two-column {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &.inverse-order {
    .column-small {
      @include media-breakpoint-up(md) {
        order: 1;
        margin-left: $grid-gutter-width;
        margin-right: 0;
      }
    }

    .column-content {
      @include media-breakpoint-up(md) {
        order: 0;
      }
    }
  }

  .column-small {
    width: 100%;
    min-width: 100%;
    order: 1;

    @include media-breakpoint-up(md) {
      margin-right: $grid-gutter-width;
      order: 0;
    }

    @include media-breakpoint-only(md) {
      width: 320px;
      min-width: 330px;
    }

    @include media-breakpoint-only(lg) {
      width: 330px;
      min-width: 330px;
    }

    @include media-breakpoint-up(xl) {
      width: 335px;
      min-width: 335px;
    }
  }

  .column-content {
    order: 0;
    @include media-breakpoint-up(md) {
      flex-grow: 1;
      order: 1;
    }
  }
}