.toasts {
  position: fixed;
  margin: 0;
  padding: 0;
  right: $toasts-right;
  top: $toasts-top;
  z-index: $zindex-toast;

  &-item {
    position: relative;
    min-width: $toasts-item-min-width;
    max-width: $toasts-item-max-width;
    background-color: $toasts-item-background-color;
    border: $toasts-item-border-style $toasts-item-border-width $toasts-item-border-color;
    padding: $toasts-item-padding-y $toasts-item-padding-right $toasts-item-padding-y $toasts-item-padding-left;
    box-shadow: $toasts-item-box-shadow;
    font-size: $toasts-item-font-size;
    transition: $toasts-item-transition;
    transform: $toasts-item-transform;

    & + .toasts-item {
      margin-top: $toasts-item-margin-top;
    }

    &[data-control-active] {
      transform: translate(0, 0);
    }

    @each $type, $color in $toasts-types {
      &-#{$type} {
        border-left: $toasts-item-styled-border-left-width $toasts-item-styled-border-left-style $color;
        background: $toasts-item-styled-background-color;
        color: $toasts-item-styled-color;

        .toasts-item-close {
          color: $toasts-item-styled-color;
        }
      }
    }

    &-close {
      position: absolute;
      display: block;
      cursor: pointer;
      margin: 0;
      border: none;
      top: 0;
      right: 0;
      background: transparent;
      text-align: center;
      padding: $toasts-item-close-padding;
      color: $toasts-item-close-color;
      font-size: $toasts-item-close-font-size;

      @include hover-focus-active() {
        outline: none;
        color: $toasts-item-close-hover-color;
      }
    }
  }
}