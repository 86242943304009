.banners {
  background: $app-background-gradient;
  padding: $banners-padding-y $banners-padding-x;
  font-size: $banners-font-size;

  @each $property, $value in $banners-borders {
    border-#{$property}: $value;
  }

  .banner {
    border-radius: $banners-banner-border-radius;
    box-shadow: $banners-banner-drop-shadow;
    background-color: $banners-banner-color;

    @each $property, $value in $banners-banner-margins {
      margin-#{$property}: $value;
    }
    @each $property, $value in $banners-banner-paddings {
      padding-#{$property}: $value;
    }

    & + & {
      @each $property, $value in $banners-banner__plus__borders {
        border#{$property}: $value;
      }
      @each $property, $value in $banners-banner__plus__margins {
        margin-#{$property}: $value;
      }
      @each $property, $value in $banners-banner__plus__paddings {
        padding-#{$property}: $value;
      }
    }

    .link {
      font-weight: $banners-banner-link-font-weight;

      &:after {
        position: absolute;
        margin-left: 5px;
        margin-top: 5px;
        width: 16px;
        height: 16px;
        content: "";
        background-image: url(escape-svg('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9.33325 2.6665L8.39325 3.6065L12.1133 7.33317H1.33325V8.6665H12.1133L8.39325 12.3932L9.33325 13.3332L14.6666 7.99984L9.33325 2.6665Z" fill="#{$link-color}"/> </svg>'));
      }
    }
  }
}