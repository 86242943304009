$categories-list-category-display: flex;
$categories-list-category-flex-direction: column;
$categories-list-category-background: #fff;

// .categories-list-category  .thumbnail
$categories-list-category-thumbnail-order: 1;
$categories-list-category-thumbnail-margin-bottom: $spacer/2;
$categories-list-category-thumbnail-box-shadow: $panel-box-shadow;

// .categories-list-category .thumbnail .image
$categories-list-category-thumbnail-image-margin-bottom: $spacer/2;
$categories-list-category-thumbnail-image-border-radius: 3px;
$categories-list-category-thumbnail-image-drop-shadow: 0 4px 1rem rgba($black, .25);
$categories-list-category-thumbnail-image-width: 100%;
$categories-list-category-thumbnail-image-drop-shadow: none;
$categories-list-category-thumbnail-image-border-radius: 0;

// .categories-list-category .title
$categories-list-category-title-font-size: 18px;
$categories-list-category-title-line-height: 24px;
$categories-list-category-title-color: $black;
$categories-list-category-title-font-weight: 400;
$categories-list-category-title-text-align: left;
$categories-list-category-title-margin: 0;
$categories-list-category-title-order: 2;
$categories-list-category-title-background: transparent;