.products-page {

  .products-page-cart {
    width: 100%;
    min-width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
      min-width: 200px;
    }

    .btn-cart {
      &[disabled] {

        background: $gray-600;
        border-color: $gray-600;
      }
    }
  }

  .estimated-delivery-date {
    font-style: $products-page-estimated-delivery-date-font-style;
    font-size: $products-page-estimated-delivery-date-font-size;
    padding-bottom: $products-page-estimated-delivery-date-padding-bottom;
  }

  .note {
    font-style: $products-page-note-font-style;
    font-size: $products-page-note-font-size;
    padding-bottom: $products-page-note-padding-bottom;
    color: $products-page-note-color;
    font-weight: $products-page-note-note-font-weight;
    line-height: $products-page-note-note-line-height;
  }
}