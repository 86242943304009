// .views-order-submit .product
$views-order-submit-product-padding-bottom: 15px;

// .views-order-submit .product + .product
$views-order-submit-product__plus-margin-top: 15px;
$views-order-submit-product__plus-border-top: 1px solid $app-color-primary-light-3;

// .views-order-submit .product .image
$views-order-submit-product-image-width: 200px;
$views-order-submit-product-image__xs-width: 80px;
$views-order-submit-product-image-border: none;
$views-order-submit-product-image-border-radius: 12px;

// .views-order-submit .product .name
$views-order-submit-product-name-font-weight: bold;
$views-order-submit-product-name-font-size: 20px;
$views-order-submit-product-name-line-height: 22px;

// .views-order-submit .product .btn-remove
$views-order-submit-product-btn-text-transform: uppercase;
$views-order-submit-product-btn-remove-color: $danger;
$views-order-submit-product-btn-remove-paddings: (
        "left": 0
)

