h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-style: $heading-font-style;
  font-weight: $heading-font-weight;
}

h1, .h1 {
  font-size: $heading-h1-font-size !important;
  line-height: $heading-h1-line-height;
}

.label-required {
  color: $label-required-color;
  @each $property, $value in $label-required-paddings {
    padding-#{$property}: $value;
  }
}

.pre-white-space {
  white-space: pre-line;
}

.text-transform-none {
  text-transform: none;
}