.orders-history {
  .title {
    font-weight: bold;
    padding-bottom: $spacer;
  }

  .error {
    color: $danger;
    font-size: $small-font-size;
  }

  .no-orders {
    font-size: 80%;
  }

  .orders-history-item {
    & + & {
      margin-top: $orders-history-item-margin-top;
    }

    .load-more {
      text-align: center;

      > button.btn.btn-link {
        outline: none !important;
        @include hover-focus-active() {
          outline: none !important;
        }
      }
    }
  }
}