.btn-cart {
  min-width: $products-product-cart-btn-cart-min-width;

  &[disabled] {
    background: $products-product-cart-btn-cart__disabled_color;
    border-color: $products-product-cart-btn-cart__disabled_color;

    @include hover-focus-active() {
      background: $products-product-cart-btn-cart__disabled_color;
      border-color: $products-product-cart-btn-cart__disabled_color;
      cursor: default;
    }
  }

  &.btn-cart-disabled {
    display: inline-block;
    background: $products-product-cart-btn-cart-disabled__disabled_color;
    border-color: $products-product-cart-btn-cart-disabled__disabled_color;
    padding-top: $spacer * 0.25;
    padding-bottom: $spacer * 0.25;
    padding-right: $spacer * 0.25;

    @include hover-focus-active() {
      background: $products-product-cart-btn-cart-disabled__disabled_color !important;
      border-color: $products-product-cart-btn-cart-disabled__disabled_color !important;
      cursor: default !important;
    }
  }
}