.modals {
  &-overlay {
    z-index: $zindex-modal-backdrop;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $modals-overlay-background-color;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  &-wrapper {
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
  }

  &-modal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: #{$spacer} 0 #{$spacer};
    min-width: map-get($container-max-widths, "sm");
    max-width: map-get($container-max-widths, "sm");
    background: $modals-modal-background-color;
    border-radius: $modals-modal-border-radius;

    @media (max-width: 540px) {
      min-width: 100%;
      max-width: 100%;

      min-width: calc(100% - #{$spacer*2});
      max-width: calc(100% - #{$spacer*2});
    }

    &-transparent {
      background: transparent;
    }


    &-md {

      min-width: map-get($container-max-widths, "md");
      width: map-get($container-max-widths, "md");
      max-width: map-get($container-max-widths, "md");

      @include media-breakpoint-down(sm) {
        min-width: 100%;
        max-width: 100%;

        min-width: calc(100% - #{$spacer*2});
        max-width: calc(100% - #{$spacer*2});
      }
    }

    &-lg {

      min-width: map-get($container-max-widths, "lg");
      width: map-get($container-max-widths, "lg");
      max-width: map-get($container-max-widths, "lg");

      @include media-breakpoint-down(md) {
        min-width: 100%;
        max-width: 100%;

        min-width: calc(100% - #{$spacer*2});
        max-width: calc(100% - #{$spacer*2});
      }
    }

    &-xl {
      min-width: map-get($container-max-widths, "xl");
      width: map-get($container-max-widths, "xl");
      max-width: map-get($container-max-widths, "xl");

      @include media-breakpoint-down(lg) {
        min-width: 100%;
        max-width: 100%;

        min-width: calc(100% - #{$spacer*2});
        max-width: calc(100% - #{$spacer*2});
      }
    }

    &-fluid {
      min-width: calc(100% - #{$spacer*2});
      width: calc(100% - #{$spacer*2});
      max-width: calc(100% - #{$spacer*2});
    }

    &-close {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      padding: 0;
      margin: 0;
      text-align: center;
      transition: color 0.3s;
      width: $modals-modal-close-size;
      height: $modals-modal-close-size;
      color: $modals-modal-close-color;
      line-height: $modals-modal-close-size;

      @include hover-focus-active() {
        color: $modals-modal-close-active-color;
      }
    }

    &-header {
      text-align: left;
      padding: $modals-modal-header-padding-y $modals-modal-header-padding-x;
      border-bottom: $modals-modal-header-border-bottom-width $modals-modal-header-border-bottom-style $modals-modal-header-border-bottom-color;
      color: $modals-modal-header-color;
      font-size: $modals-modal-header-font-size;

      @include media-breakpoint-down(sm) {
        font-size: $modals-modal-header__sm-font-size;
      }
    }

    &-body {
      padding: $modals-modal-body-padding-y $modals-modal-body-padding-x;

      &-title {
        font-size: $modals-modal-body-title-font-size;
        padding-bottom: $modals-modal-body-title-padding-bottom;
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      padding: $modals-modal-footer-padding-y $modals-modal-footer-padding-x;
      text-align: $modals-modal-footer-text-align;

      button {
        flex: 1;
        margin: $modals-modal-footer-button-margin-y $modals-modal-footer-button-margin-x;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}