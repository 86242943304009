// .banners
$banners-background-color: $app-background-gradient;
$banners-padding-y: $spacer;
$banners-padding-x: 0;
$banners-font-size: 90%;
$banners-border-color: darken($white, 10%);

$banners-borders: (
        "top": none,
        "right": none,
        "bottom": none,
        "left": none
);

// .banners .banner
$banners-banner-border-radius: 5px;
$banners-banner-drop-shadow: 0 4px 1rem rgba($black, .25);
$banners-banner-color: $white;
$banners-banner-margins: (
        "top": 0,
        "right": 0,
        "bottom": 15px,
        "left": 0
);
$banners-banner-paddings: (
        "top": 15px,
        "right": 15px,
        "bottom": 15px,
        "left": 15px
);

// .banners .banner + .banner
$banners-banner__plus__borders: (
        "top": none,
        "right": none,
        "bottom": none,
        "left": none
);
$banners-banner__plus__margins: (
        "top": 0,
        "right": 0,
        "bottom": 0,
        "left": 0
);
$banners-banner__plus__paddings: (
        "top": 0,
        "right": 0,
        "bottom": 0,
        "left": 0
);

// .banners .banner .link
$banners-banner-link-font-weight: 600;

