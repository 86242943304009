.form-group {

  &.label-large {
    label {
      font-size: $form-group-label-large-label-font-size;
    }
  }

  label {
    font-weight: $form-group-label-font-weight;
    text-transform: $form-group-label-text-transform;
    color: $form-group-label-color;
    line-height: $form-group-label-line-height;
    font-size: $form-group-label-font-size;
    @each $property, $value in $form-group-label-margins {
      margin-#{$property}: $value;
    }
  }

  .form-control {
    min-height: $form-control-height;
    padding: $form-control-padding;
    border: 1px solid $form-control-border-color;
    border-radius: $form-control-border-radius;
    font-size: $form-control-font-size;
    line-height: $form-control-line-height;
    background: $form-control-background;

    &[disabled] {
      background: $form-control-disabled-background;
    }

    @include placeholder {
      text-transform: $form-control-placeholder-text-transform;
      font-style: $form-control-placeholder-font-style !important;
    }

    .form-control-date {
      &[disabled] {
        background: $form-control-background !important;
      }
    }
  }
}

.radio-large {
  height: 23px;
  width: 23px;
}

.form-control.is-valid, .was-validated .form-control:valid,
.form-control.is-invalid, .was-validated .form-control:invalid {
  padding-right: $form-control-padding !important;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block !important;
}