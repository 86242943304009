// .products-list-product .title
$products-list-product-title-font-size: 18px;
$products-list-product-title__md-font-size: 80%;
$products-list-product-title-line-height: 24px;
$products-list-product-title-color: $app-color-black;
$products-list-product-title-font-weight: normal;

// .products-list-product .title .note
$products-list-product-title-note-font-size: 90%;
$products-list-product-title-note-font-style: italic;
$products-list-product-title-note__md-font-size: 80%;
$products-list-product-title-note-color: $app-color-gray-dark;
$products-list-product-title-note-font-weight: normal;
$products-list-product-title-note-font-style: normal;
$products-list-product-title-note-font-size: 12px;
$products-list-product-title-note-line-height: 16px;

// .products-list-product .image
$products-list-product-image-margin: 0;
$products-list-product-image-display: block;
$products-list-product-image-width: 100%;
$products-list-product-image-border: none;

// .products-list-product .cart-toast .message
$products-list-product-cart-toast-message-padding-top: 5px;
$products-list-product-cart-toast-message-text-align: center;
$products-list-product-cart-toast-message-font-size: 70%;

// .products-product-cart .btn-cart
$products-product-cart-btn-cart-min-width: auto;

// .products-product-cart .btn-cart[disabled]
$products-product-cart-btn-cart__disabled_color: $gray-600;
// .products-product-cart .btn-cart-disabled
$products-product-cart-btn-cart-disabled__disabled_color: #a1a5ab;