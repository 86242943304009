.auth-login .auth-banner {
  border-top-right-radius: 0;
  display: block;
  color: $views-auth-login-banner-color;
  background: $views-auth-login-banner-background-color;
  @each $property, $value in $views-auth-login-banner-paddings {
    padding-#{$property}: $value;
  }
  @each $property, $value in $views-auth-login-banner-margins {
    margin-#{$property}: $value;
  }
}