.survey-delivery {

  .product {
    margin-bottom: 15px;

    .form-check label {
      font-size: 12px;
    }

    .thumbnail {
      margin-right: $spacer;
      max-width: 100px;
      width: 100%;
    }

    .tracking {
      padding-top: $spacer;
    }
  }
}