//.products-list-product .description
$products-product-description-font-size: 12px;

//.products-list-product .description .text
$products-list-product-description-font-weight: normal;
$products-list-product-description-font-size: 12px;
$products-list-product-description-padding-top: 10px;
$products-list-product-description-line-height: 16px;
$products-list-product-description-color: #737373;

//.products-list-product .description .link
$products-list-product-description-link-font-size: $products-list-product-description-font-size;
$products-list-product-description-link-line-height: 20px;
$products-list-product-description-link-color: #000;